import React, { useState } from "react"

//Utils
import { useSelector } from "react-redux"
import { category_link, shop_link } from "components/links"
import { MenuData } from "../utils/data-sample"

//Components
import { Link, Button } from "components/anti"
import router from "next/router"

const DesktopMenu = ({ handleSelectMenu, megamenu }) => {
  // States *********************************************************************** //
  const { user_data, my_carts } = useSelector((state) => state.auth)
  const { categories, brands } = useSelector((state) => state.general)

  const filteredcategories = categories.filter(
    (item) => item.slug !== "mcd-x-coca-cola"
  )
  // States *********************************************************************** //

  const handleHover = (data, show) => {
    if (show) {
      handleSelectMenu({ show, data: { ...data } })
    } else {
      handleSelectMenu({ show, data })
    }
  }

  return (
    <>
      <ul className="navbar-nav">
        {filteredcategories.map((item) => (
          <li
            className="nav-item"
            key={`${item.id}-desktop`}
            onMouseOver={() => handleHover(item, true)}
            onMouseLeave={() => handleHover(item, false)}
          >
            <div
              onClick={() => {
                handleSelectMenu(false)
              }}
            >
              <Link
                className="nav-link"
                // activeClassName="active"
                to={`${shop_link}/${item.slug}-${item.id}`}
              >
                {item.title}
              </Link>
            </div>
          </li>
        ))}
      </ul>
    </>
  )
}

export default DesktopMenu
